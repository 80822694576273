<template>
  <div v-show="index === currentIndex">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "tabPane",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss"></style>
