<template>
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item">
      <router-link to="/">首页</router-link>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="#">
        产品
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <ul class="submenu">
        <li><router-link to="/blog-grid">政务便民服务自助终端</router-link></li>
        <li>
          <router-link to="/blog-grid-sidebar">K12自助终端</router-link>
        </li>
        <li><router-link to="/blog-list">高校自助终端</router-link></li>
        <li>
          <router-link to="/blog-list-sidebar">医务自助终端</router-link>
        </li>
      </ul>
    </li>
    <li class="about">
      <router-link class="custom-link" to="/about">关于我们</router-link>
    </li>
    <li class="about">
      <div
        class="jumpname"
        @click="jumpOther"
        style="
          height: 80px;
          line-height: 80px;
          font-size: 16px;
          font-weight: 500;
          padding: 0 17px;
        "
      >
        信息发布
      </div>
    </li>
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
  name: "Nav",
  components: { Icon },
  methods: {
    jumpOther() {
      window.open("http://www.longdaa.com/hcdg-web/#/");
    },
  },
};
</script>
<style scoped lang="scss">
/* class="custom-link router-link-active" */
.mainmenu >>> li .router-link-exact-active.router-link-active {
  /* background-color: red; */
  /* border-bottom: 2px solid blue; */
  color: rgb(0, 101, 255);
}
.mainmenu-nav {
  .jumpname {
    cursor: pointer;

    &:hover {
      color: #059dff;
    }
  }
}
</style>
