<template>
  <div class="foot">
    <div class="foot-box3">
      <div class="left">
        <div class="leftpart">
          <img src="../../../assets/images/new/phone.png" alt="" />
          <span>电话：18205691005</span>
        </div>
        <div class="leftpart">
          <img src="../../../assets/images/new/email.png" alt="" />
          <span>邮箱：50903016@qq.com</span>
        </div>
        <div class="leftpart" style="display: flex">
          <img
            style="height: fit-content"
            src="../../../assets/images/new/address.png"
            alt=""
          />
          <span>公司地址：南京市浦口区浦滨路150号中科创新广场21栋4楼</span>
        </div>
      </div>
      <div class="right">
        <div class="title">互动平台</div>
        <div class="rightbox">
          <div class="box">
            <img src="../../../assets/images/new/vyinmini.jpg" alt="" />
            <p>微印小程序</p>
          </div>
          <div class="box">
            <img src="../../../assets/images/new/yun.jpg" alt="" />
            <p>云打印小程序</p>
          </div>
          <div class="box">
            <img src="../../../assets/images/new/vyin.jpg" alt="" />
            <p>微印公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw2">
      <p>版权所有 南京龙达信息技术有限公司</p>
      <a href="https://beian.miit.gov.cn/" target="_blank" rel=""
        >苏ICP备2022028761号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterThree",
  props: {
    data: {
      default: null,
    },
  },
};
</script>
<style lang="sass">
@import '@/assets/scss/footer/foot.scss'
</style>
