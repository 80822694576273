<template>
  <main class="page-wrapper">
    <Header :data="$props" />

    <img
      style="margin-top: -30px; width: 100%"
      src="../assets/images/new/Group1712.png"
      alt=""
    />

    <div class="tabLine">
      <div style="text-align: center">
        <div
          class="box"
          v-for="(item, index) in tabList"
          :key="index"
          :style="{ color: item.isColor, backgroundColor: item.isBgc }"
          @click="colorChange(item, index)"
        >
          {{ item.name }}
        </div>
        <!-- 公司简介 -->
        <div class="tabContant" v-show="tabId == 0">
          <div>
            <img src="../assets/images/new/Frame5410.png" alt="" />
            <img
              src="../assets/images/new/Frame5288.png"
              alt=""
              style="margin-left: 3%"
            />
          </div>
          <div class="mechinePart" style="background-color: #f9faff">
            <img src="../assets/images/new/Group220.png" alt="" />
            <div class="groupList">
              <div class="groupdiv" @click="mechineChoose('1')">
                <img src="../assets/images/new/Group755.png" alt="" />
                <div class="clickLine" v-show="mechineShow == '1'"></div>
              </div>
              <div class="groupdiv" @click="mechineChoose('2')">
                <img
                  src="../assets/images/new/Group756.png"
                  alt=""
                  style="margin-top: 3%"
                />
                <div class="clickLine" v-show="mechineShow == '2'"></div>
              </div>
              <div class="groupdiv" @click="mechineChoose('3')">
                <img src="../assets/images/new/Group757.png" alt="" />
                <div class="clickLine" v-show="mechineShow == '3'"></div>
              </div>
              <div class="groupdiv" @click="mechineChoose('4')">
                <img src="../assets/images/new/Group754.png" alt="" />
                <div class="clickLine" v-show="mechineShow == '4'"></div>
              </div>
            </div>

            <!-- 机器下的详情
             -->
            <div class="mechineDetails" v-show="mechineShow == '1'">
              <p style="font-size: 1.5vw; color: #000">
                立足小区治理的网格化便民服务智能终端
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                微事通(便民)江苏龙达积极响应国家“+四五”规划提出:“加快城乡社区服务体系建设，充分发挥全国一体化政务服务平台作用，推动“互联网+政务服务”向乡镇(道）、（社区）延伸覆盖。社区服务设施开放和服务时间一般不少于每天8小时。群众关切项目应开展24小时线上服务，保留必要线下办事服务渠道，及时响应居民需求，加快部署政务通自助一体化服务，完善村(社区)政务自助便民服务网络布局.....。产品以道为中心，在便民服务方面持续探索，以“公共服务不出网格”为目标，真正做到事项要高频、功能要集成、权限要打通、场景要落地、群众要有感。
              </p>
            </div>

            <div class="mechineDetails" v-show="mechineShow == '2'">
              <p style="font-size: 1.5vw; color: #000">
                立足精准教学的数字校园自助智能终端
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                校务通(K12)由我司潜心专研开发的全国首个精准教学+慧学服务自助云打印智能终端。
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                主要解决学校因为教学和校务需要而产生的资料打印复印。通过微印精准教学四大模块六大系统整体提升合作学校教学的针对性和有效性，缩小与一类学校均分差。
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                K12校务智能自助终端以校务的全过程管理为轴:线，对校办、教务、班级等产生的作业、通知、证明、成绩单、申请表等各类通用、制式化的文件资料实现一处查询、预览，一键打印。
              </p>
            </div>

            <div class="mechineDetails" v-show="mechineShow == '3'">
              <p style="font-size: 1.5vw; color: #000">
                立足各大高校的数字服务自助只能终端
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                校务通(高校)是我司潜心专研开发的全国首个校务服务+惠学服务自助云打印智能终端。
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                校务通(高校)介绍校务通(高校)以校务服务的全过程管理为轴线，对校办、教务、团委、后勤，学生自身等学习、通知、证明、准考证、成绩单、申请表等各类通用、制式化的文件、附件资料实现一号登录一处查询、预览，一键打印。
              </p>
            </div>

            <div class="mechineDetails" v-show="mechineShow == '4'">
              <p style="font-size: 1.5vw; color: #000">
                立足于智慧医院的数字医疗自助智能终端
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                随着智慧地球、健康中国时代脚步的来临，互联网+医疗健康成为主流趋势江苏龙达信息科技有限公司，作为互联网+医疗健康整体解决方案提供商，建立国内互联网+医疗健康区域服务平台和标准智慧医院概念，致力于为患者提供就诊全流程优化服务。
              </p>
              <p style="font-size: 0.9vw; color: #6d6d6d">
                通过构建线上用户核心系统管理平台、统一预约平台、统一对账平台、统一支付平台、智慧导引平台、互联网医院、数据智能平台等，并辅以线下排队及信息发布终端、自助服务终端、健康管理数据采集终端、诊间结算终端提供从咨询、预约、一卡就诊、电子病历管理、诊间支付等就诊全流程优化服务,打造线上+线下的智慧医院020闭环服务生态系统，从而解决就诊过程的三长一短”问题,改善医患关系，提升医院管理效率，提高患者满意度。
              </p>
            </div>
          </div>
          <div style="width: 100%; background-color: #fff">
            <img
              src="../assets/images/new/Group758.png"
              alt=""
              style="display: block; margin: 0 auto"
            />

            <img
              src="../assets/images/new/Group1779.png"
              alt=""
              style="display: block; margin: 0 auto; width: 60%"
            />
          </div>
        </div>
        <div class="tabContant" v-show="tabId == 1">
          <img
            src="../assets/images/new/schools1.png"
            alt=""
            style="margin-top: 60px; width: 60%"
          />
          <img
            src="../assets/images/new/schools2.png"
            alt=""
            style="margin-top: 20px; margin-bottom: 100px; width: 60%"
          />
        </div>

        <!-- 合作申请 -->
        <div class="tabContant" v-show="tabId == 2" style="padding-top: 0">
          <div class="cooperationPart">
            <div class="titles">欢迎您加入龙达科技的合作团队！</div>
            <!-- <div class="line"></div> -->
            <div class="formBox">
              <Form :model="formItem" :label-width="120" inline>
                <div class="title">联系信息</div>
                <FormItem label="姓名" style="width: 45%">
                  <Input
                    v-model="formItem.name"
                    placeholder="请输入姓名"
                  ></Input>
                </FormItem>
                <FormItem label="电子邮箱地址" style="width: 45%">
                  <Input
                    v-model="formItem.email"
                    placeholder="请输入电子邮箱地址"
                  ></Input>
                </FormItem>
                <FormItem label="联系电话" style="width: 45%">
                  <Input
                    v-model="formItem.phone"
                    placeholder="请输入联系电话"
                  ></Input>
                </FormItem>
                <FormItem label="地址" style="width: 45%">
                  <Input
                    v-model="formItem.address"
                    placeholder="请输入地址"
                  ></Input>
                </FormItem>

                <div class="title">合作类型</div>
                <FormItem label="合作意向" style="width: 45%">
                  <Select v-model="formItem.select">
                    <Option value="beijing">New York</Option>
                    <Option value="shanghai">London</Option>
                    <Option value="shenzhen">Sydney</Option>
                  </Select>
                </FormItem>
                <FormItem label="合作领域" style="width: 45%">
                  <Select v-model="formItem.select">
                    <Option value="beijing">New York</Option>
                    <Option value="shanghai">London</Option>
                    <Option value="shenzhen">Sydney</Option>
                  </Select>
                </FormItem>
                <FormItem
                  label="合作详情"
                  style="width: 100%; float: left; margin-left: 4%"
                >
                  <Input
                    style="width: 38%; float: left"
                    v-model="formItem.textarea"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 100 }"
                    placeholder="请输入相关信息描述"
                  ></Input>
                </FormItem>

                <div class="title">上传附件</div>
                <FormItem
                  label="上传附件:"
                  style="width: 100%; float: left; margin-left: 4%"
                >
                  <Upload
                    multiple
                    type="drag"
                    action="//jsonplaceholder.typicode.com/posts/"
                    style="width: 30%"
                  >
                    <div style="padding: 20px 0">
                      <Icon
                        type="ios-cloud-upload"
                        size="52"
                        style="color: #3399ff"
                      ></Icon>
                      <p
                        style="
                          color: #3399ff;
                          width: 80%;
                          margin: auto;
                          font-size: 0.8vw;
                        "
                      >
                        可以收藏相关文件，如合作提案、公司介绍等
                      </p>
                    </div>
                  </Upload>
                </FormItem>
                <br />

                <div class="title" style="display: block; font-size: 1vw">
                  <Checkbox v-model="single"
                    >同意本网站的<span
                      @click="explainModal = true"
                      style="color: #3399ff"
                      >相关隐私隐私政策</span
                    >，以确保数据保护合规性。</Checkbox
                  >
                </div>

                <FormItem class="subBtn" style="width: 100%; margin-top: 30px">
                  <Button type="primary" @click="submitBtn">提交</Button>
                  <Button style="margin-left: 8px">取消</Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>

        <Modal
          v-model="explainModal"
          @on-ok="ok"
          @on-cancel="cancel"
          ok-text="同意"
          cancel-text="不同意"
        >
          <p style="color: #000; font-weight: 700">个人隐私保护政策</p>
          <p>
            我公司（江苏龙达信息技术有限公司）非常重视用户信息的保护。您在使用我们的相关服务（以下简称“服务），并在相应页面填写个人信息前，<span
              style="color: #000; font-weight: 700"
              >请您务必仔细阅读本声明</span
            >。您一旦主动在页面填写您的个人信息并进行提交操作，即意味着您同意我们按照本声明收集、使用、共享您的相关信息。<br />
            一、个人信息的收集<br />
            1、我们提供服务时，可能会收集下列与您有关的个人信息：<br />
            您在本公司网站各页面所涉及的表单中输入/选择的信息包括：姓名、手机号，邮箱，身份证等。请您注意，具体需要填写的信息可能会根据我们提供的产品/服务的不同而有所差异，请以届时向您展示的服务以及所对应的要求填写相关个人信息，但我们会在下文向您说明相关个人信息的收集和使用规则。<br />
            2、您需要注意的是，<span style="color: #000; font-weight: 700"
              >以上个人信息均是您自愿提供</span
            >。您有权拒绝提供，但可能无法正常使用相对应的服务，功能或者无法达到服务拟预设的成果。<br />
            3、对于<span style="color: #000; font-weight: 700"
              >不满18岁的用户，须在其监护人陪同未成年人用户已经阅读和理解本声明</span
            >并且许可的情况下向我们提交相关个人信息。<br />
            二、个人信息的使用和共享规则<br />
            1、您同意，我们可以通过以下方式对个人信息进行使用和共享： <br />
            (1)
            我们收集您的姓名、手机号用于及时与您取得联系，并通过人工或语音机器人的方式与您沟通产品或服务的相关事宜。<br />
            (2)
            如您拟撤回授权/删除您提交的个人信息的，您可以与我们联系，我们将尽快配合处理。<br />
            2、为了实现上述目的，我们可能会将您的必要信息共享给我们的技术合作伙伴（例如语音机器人服务提供商），并由其在服务范围内进行合法、正当、必要地使用和处理。<br />
            3、<span style="color: #000; font-weight: 700"
              >我们将严格保护您的个人信息安全</span
            >。我们将采用适当制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。<br />
            三、适用法律 1、本声明适用中华人民共和国法律。<br />
            2、如果您需要查询、修改或更正您的个人信息，或对个人信息保护问题有任何疑问或投诉，您可以与我们联系，我们将尽快配合处理。<br />
            四、变更<br />
            我们会适时对本声明进行修订，该等修订构成本声明的一部分。公司会将修改后的声明予以发布。在该种情况下，若您继续使用我们的服务，即表示同意接受经修订的声明的约束。
          </p>
        </Modal>

        <!-- 招聘 -->
        <div class="tabContant11" v-show="tabId == 3">
          <div class="offerPart">
            <div class="offerName">
              <div class="titles">职位类别</div>
              <!-- 具体职位 -->
              <div
                v-for="(item, index) in jobList"
                :key="index"
                style="display: inline-block; width: 33%"
              >
                <div
                  class="box"
                  :style="{
                    color: item.isColor,
                    backgroundColor: item.isBgc,
                  }"
                  @click="jobChange(item, index)"
                >
                  {{ item.part }}
                </div>
              </div>
              <div class="jobss">
                <div v-for="(item1, index) in chooseJobOne" :key="item1.id">
                  <div
                    class="jobname"
                    :style="{
                      color: item1.isColor,
                      borderBottom: item1.isBorder,
                    }"
                    @click="jobssClick(item1, index)"
                  >
                    {{ item1.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="offerDetails">
              <div class="titles">
                <div class="name">
                  {{ jobDetails.name }}
                </div>
                <div class="money">薪资范围:{{ jobDetails.money }}</div>
              </div>
              <img
                class="Group1686"
                src="../assets/images/new/Group1686.png"
                alt=""
              />
              <div class="work">
                <div class="title">职位描述：</div>
                <div
                  class="details"
                  v-for="(item, index) in jobDetails.contant"
                  :key="index"
                >
                  {{ item.text }}
                </div>

                <div class="title">岗位要求：</div>
                <div
                  class="details"
                  v-for="(item, index) in jobDetails.experience"
                  :key="index"
                >
                  {{ item.text }}
                </div>
              </div>
              <div class="goSend" @click="sendModal = true">立即投递</div>
            </div>
          </div>
        </div>

        <!-- 投递简历 -->
        <Modal
          v-model="sendModal"
          @on-ok="sendOK"
          @on-cancel="sendcancel"
          ok-text="确定投递"
          cancel-text="取消"
          class="sendbox"
        >
          <p style="color: #0728b9; font-weight: 700">岗位投递</p>
          <div class="formBox">
            <Form :model="sendItem" :label-width="130" inline>
              <div class="sendtitle">基本信息</div>
              <FormItem label="姓名" style="width: 70%">
                <Input v-model="sendItem.name" placeholder="请输入姓名"></Input>
              </FormItem>
              <FormItem label="电子邮箱地址" style="width: 70%">
                <Input
                  v-model="sendItem.email"
                  placeholder="请输入电子邮箱地址"
                ></Input>
              </FormItem>
              <FormItem label="联系电话" style="width: 70%">
                <Input
                  v-model="sendItem.phone"
                  placeholder="请输入联系电话"
                ></Input>
              </FormItem>

              <div class="sendtitle">上传附件</div>
              <FormItem
                label="上传附件:"
                style="width: 100%; float: left; margin-left: 4%"
              >
                <Upload
                  multiple
                  type="drag"
                  action="//jsonplaceholder.typicode.com/posts/"
                  style="width: 50%"
                >
                  <div style="padding: 20px 0">
                    <Icon
                      type="ios-cloud-upload"
                      size="52"
                      style="color: #3399ff"
                    ></Icon>
                    <p
                      style="
                        color: #3399ff;
                        width: 80%;
                        margin: auto;
                        font-size: 0.8vw;
                      "
                    >
                      请上传相关简历信息
                    </p>
                  </div>
                </Upload>
              </FormItem>
            </Form>
          </div>
        </Modal>

        <div class="tabContant" v-show="tabId == 4">
          <img
            src="../assets/images/new/Frame5374.png"
            style="width: 56%"
            alt=""
          />
        </div>
      </div>
    </div>

    <Footer :data="$props" />
  </main>
</template>

<script>
import Header from "../components/common/header/Header";
import Footer from "../components/common/footer/Footer";

export default {
  name: "About",
  components: { Footer, Header },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isContent1: true,
      isContent2: false,
      isContent3: false,
      isColor: "#333",
      isBgc: "rgb(238, 242, 255)",
      tabId: 0,
      single: false,
      mechineShow: "1",
      tabList: [
        {
          id: 0,
          name: "公司简介",
          isColor: "#fff",
          isBgc: "rgb(49, 116, 205)",
          isClicked: true,
        },
        {
          id: 1,
          name: "合作伙伴",
          isColor: "#333",
          isBgc: "rgb(238, 242, 255)",
          isClicked: false,
        },
        {
          id: 2,
          name: "合作申请",
          isColor: "#333",
          isBgc: "rgb(238, 242, 255)",
          isClicked: false,
        },
        {
          id: 3,
          name: "加入我们",
          isColor: "#333",
          isBgc: "rgb(238, 242, 255)",
          isClicked: false,
        },
        {
          id: 4,
          name: "联系我们",
          isColor: "#333",
          isBgc: "rgb(238, 242, 255)",
          isClicked: false,
        },
      ],

      formItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        select: "",
        radio: "male",
        checkbox: [],
        switch: true,
        date: "",
        time: "",
        slider: [20, 50],
        textarea: "",
      },

      explainModal: false,
      partId: 0,
      chooseJobOne: [],
      jobDetails: [],
      jobList: [
        {
          id: 0,
          part: "研发类",
          isColor: "#fff",
          isBgc: "rgb(3, 70, 175)",
          isClicked: true,
          jobsArr: [
            {
              id: 0,
              name: "前端开发工程师",
              isColor: "#0728B9",
              isBorder: "2px solid rgb(0, 128, 254)",
              isClicked: true,
              detail: {
                id: 0,
                name: "前端开发工程师",
                area: "南京",
                years: "1-3年",
                money: "7-10K",
                education: "大学本科",
                contant: [
                  {
                    text: "1、公办全日制本科及以上学历；",
                  },
                  {
                    text: "2、精通前端开发语言，熟悉编程规范和Web安全规范；",
                  },
                  {
                    text: "3、精通原生js的dom操作和数据处理；",
                  },
                  {
                    text: "4、熟悉vue2，vue3，了解react等主流前端框架；",
                  },
                  {
                    text: "5、熟悉重构技术和设计模式优先，了解three.js/echarts.js加分；",
                  },
                  {
                    text: "6、有一定工作强度，能吃苦耐劳",
                  },
                ],
                experience: [
                  {
                    text: "1、有一定的组件化编程思想和理论基础；",
                  },
                  {
                    text: "2、具有前端框架或页面逻辑详细设计经验者优；",
                  },
                  {
                    text: "3、有 amis/echarts或相关开发经验者优先；",
                  },
                  {
                    text: "4、能独立完成开发项目，并具备一定的组织协调能力、技术指导能力；",
                  },
                ],
              },
            },
          ],
        },

        {
          id: 1,
          part: "运营类",
          isColor: "black",
          isBgc: "#fff",
          isClicked: false,
          jobsArr: [
            {
              id: 0,
              name: "电话网络销售",
              isColor: "#0728B9",
              isBorder: "2px solid rgb(0, 128, 254)",
              isClicked: true,
              detail: {
                id: 0,
                name: "电话网络销售",
                area: "南京",
                years: "1-3年",
                money: "15-30K",
                education: "大学本科",
                contant: [
                  {
                    text: "1、招聘:电话销售",
                  },
                  {
                    text: "2、薪待资遇:底薪5k+提成+五险+人才引进奖+生日福利+每周下午茶+公费旅游，人均月收入3-5万",
                  },
                  {
                    text: "3、上时班间:9:00-12:00，14:00-18:00调休，纯办公室办公，公司提供资源，无需外出。",
                  },
                ],
                experience: [
                  {
                    text: "根据公司的客户资源，向客户推广公司的产品，提供手机，通过电话，微信邀约客户到访。",
                  },
                ],
              },
            },
          ],
        },
      ],

      sendModal: false,
      sendItem: {
        name: "",
        email: "",
        phone: "",
      },
    };
  },
  created() {
    this.chooseJobOne = this.jobList[0].jobsArr;
    this.jobDetails = this.jobList[0].jobsArr[0].detail;
  },
  methods: {
    colorChange(item, index) {
      this.tabList[index].isColor = "#fff";
      this.tabList[index].isBgc = "rgb(49, 116, 205)";
      this.tabId = this.tabList[index].id;
      this.tabList.forEach(item => {
        if (item.id != index) {
          item.isColor = "#333";
          item.isBgc = "rgb(238, 242, 255)";
        }
      });
    },

    jobChange(item, index) {
      this.jobList[index].isColor = "#fff";
      this.jobList[index].isBgc = "rgb(49, 116, 205)";
      this.jobList[index].isClicked = true;
      this.chooseJobOne = this.jobList[index].jobsArr;
      this.jobDetails = this.jobList[index].jobsArr[0].detail;
      this.jobList.forEach(item => {
        if (item.id != index) {
          item.isColor = "black";
          item.isBgc = "#fff";
          item.isClicked = false;
        }
      });
    },

    // 点击具体职位名
    jobssClick(item, index) {
      this.jobDetails = item.detail;
      this.chooseJobOne[index].isColor = "#0728B9";
      this.chooseJobOne[index].isBorder = "2px solid rgb(0, 128, 254)";
      this.chooseJobOne[index].isClicked = true;
      this.chooseJobOne.forEach(item => {
        if (item.id != index) {
          item.isColor = "#6D6D6D";
          item.isBorder = "2px solid  #DCDCDC";
          item.isClicked = false;
        }
      });
    },

    mechineChoose(index) {
      this.mechineShow = index;
    },
    OK() {
      this.$Message.success("提交成功！");
    },

    cancel() {},

    sendOK() {
      this.$Message.success("投递成功！");
    },

    sendcancel() {},

    submitBtn() {
      this.$Message.success("提交成功！");
    },
  },
};
</script>
<style scoped>
.tabLine {
  width: 100%;
  background-color: #f9faff;
  /* display: flex; */
}
.box {
  width: 10%;
  height: 50px;
  background-color: rgb(238, 242, 255);
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  border: 1px solid #eef2ff;
}

.tabLine .tabContant {
  width: 100%;
  /* height: 20vh; */
  background-color: #fff;
  padding-top: 20px;
}
.mechinePart {
  width: 100%;
  /* height: 200px; */
  /* background-color: red; */
  margin-top: 20px;
}

.groupList {
  display: flex;
  width: 50%;
  margin-left: 25%;
  padding-top: 30px;
}
.groupList .groupdiv {
  flex: 1;
}
.groupList .groupdiv img {
  display: block;
  margin: auto;
}

.clickLine {
  width: 90%;
  height: 5px;
  background-color: #2564f4;
  display: inline-block;
}

.mechineDetails {
  margin-top: 40px;
  width: 50%;
  margin-left: 25%;
  /* height: 300px; */
  /* background-color: #2564f4; */
  text-align: left;
  background-color: #f9faff;
  padding-bottom: 50px;
}

/* 合作 */
.cooperationPart {
  width: 60%;
  height: auto;
  /* background-color: #2564f4; */
  /* border: #2564f4 1px solid; */
  margin: auto;
}
.cooperationPart .titles {
  width: 100%;
  font-size: 1.2vw;
  font-weight: 700;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  line-height: 100px;
  text-align: left;
  color: #333;
  border-bottom: #2564f4 2px solid;
}

/* .cooperationPart .line {
  width: 100%;
  height: 2px;
  background-color: #69aff5;
  margin-top: 7%;
} */

.cooperationPart .formBox {
  width: 94%;
  /* height: 170vh; */
  margin: auto;
  margin-top: 40px;
}
.cooperationPart .formBox .title {
  font-size: 1vw;
  font-weight: 700;
  color: #333;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 20px;
}

.formBox >>> .ivu-form .ivu-form-item-label {
  font-size: 17px;
}

.formBox >>> .ivu-input {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}
.formBox >>> input::-webkit-input-placeholder {
  /* WebKit browsers */
  /* font-size: 0.7vw; */
  font-size: 16px;
  color: #babcbf;
}

.formBox >>> .ivu-select-selection .ivu-select-placeholder {
  font-size: 0.7vw;
  color: #babcbf;
  text-align: left;
}
.formBox >>> .ivu-select-selection {
  text-align: left;
}

.formBox >>> .ivu-checkbox-wrapper {
  font-size: 0.8vw;
}

.subBtn >>> .ivu-form-item-content {
  float: right;
}

.tabContant11 {
  width: 100%;
  background-color: #fff;
  height: 100vh;
  padding-top: 20px;
}
.offerPart {
  width: 60%;
  display: flex;
  margin: auto;
  height: 100%;
}

.offerName {
  flex: 1;
}
.offerName .titles {
  text-align: left;
  color: #333;
  font-size: 1.2vw;
  font-weight: 700;
}

.offerName .box {
  width: 90%;
  height: 42px;
  background-color: rgb(238, 242, 255);
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  border: 1px solid rgb(3, 70, 175);
  margin-right: 7%;
  border-radius: 42px;
  margin-top: 20px;
}

.offerName .jobss {
  width: 100%;
  height: 100px;
  /* background-color: blue; */
  padding-top: 30px;
  text-align: left;
}

.jobss .jobname {
  display: block;
  width: 94%;
  margin-left: 10px;
  /* border-bottom: 1px solid blue; */
  line-height: 60px;
  margin-bottom: 20px;
  font-size: 24px;
}

.offerDetails {
  flex: 1;
  float: left;
  background-color: #f9fcff;
  padding: 10px 35px;
}

.offerDetails .titles {
}
.offerDetails .titles .name {
  display: inline-block;
  color: #0728b9;
  font-size: 1.3vw;
  font-weight: 700;
  text-align: left;
  width: 70%;
}
.offerDetails .titles .money {
  display: inline-block;
  float: right;
  color: #8e8e8e;
  font-size: 1vw;
  width: 30%;
}

.Group1686 {
  float: left;
  display: block;
}
.offerDetails .work {
  margin-top: 40px;
  width: 100%;
  text-align: left;
}
.offerDetails .work .title {
  font-size: 1vw;
  color: #6d6d6d;
  width: 100%;
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.goSend {
  width: 180px;
  height: 50px;
  background-color: #2564f4;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
  margin-top: 60px;
}
.sendtitle {
  font-size: 0.9vw;
  margin-bottom: 20px;
}

.sendbox >>> .ivu-modal-footer {
  border: none;
}
</style>
