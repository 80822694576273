<template>
  <main class="page-wrapper">
    <Header :data="$props" />

    <img
      style="margin-top: -30px; width: 100%"
      src="../assets/images/new/Group1707.png"
      alt=""
    />
    <div class="wordPart">
      <img
        src="../assets/images/new/Group2001.png"
        style="width: 100%"
        class="Group0000"
        alt=""
      />
      <div class="middlePart">
        <img
          src="../assets/images/new/Group1766.png"
          class="Group1765"
          alt=""
        />
        <div class="rightppart">
          <img
            src="../assets/images/new/Group1767.png"
            alt=""
            class="Group1768"
          />
          <p style="width: 90%">
            随着智慧地球、健康中国时代脚步的来临，互联网+医疗健康成为主流趋势江苏龙达信息科技有限公司，作为互联网+医疗健康整体解决方案提供商，建立国内互联网+医疗健康区域服务平台和标准智慧医院概念，致力于为患者提供就诊全流程优化服务。
          </p>
          <p style="width: 90%">
            通过构建线上用户核心系统管理平台、统一预约平台、统一对账平台、统一支付平台、智慧导引平台、互联网医院、数据智能平台等，并辅以线下排队及信息发布终端、自助服务终端、健康管理数据采集终端、诊间结算终端提供从咨询、预约、一卡就诊、电子病历管理、诊间支付等就诊全流程优化服务,打造线上+线下的智慧医院020闭环服务生态系统，从而解决就诊过程的三长一短”问题,改善医患关系，提升医院管理效率，提高患者满意度。
          </p>
        </div>
      </div>
    </div>

    <img src="../assets/images/new/Group1731.png" style="width: 100%" alt="" />

    <div class="tabbox2">
      <img :src="tabImg" class="tabImg" alt="" />

      <div class="btn1" @click="imgShow('4')"></div>
      <div class="btn2" @click="imgShow('5')"></div>
      <div class="btn3" @click="imgShow('6')"></div>
      <div class="wordbox" v-show="isContent1">
        <p style="margin-top: 10%">i5-4460四核3.2HZ /128G/H81主板10 COM</p>
        <p>正版windows</p>
        <p>身份证/社保卡/银行卡读卡器/数字键盘/二维码读卡器</p>
        <p>拾音器/扩音器</p>
        <p>打印速度30+页/分钟，250页1.55MM钢版、喷塑、32寸触摸屏</p>
        <p>HIS系统对接(选配）</p>
      </div>

      <div class="wordbox" v-show="isContent2">
        <p style="margin-top: 10%">待更新</p>
      </div>

      <div class="wordbox" v-show="isContent3">
        <p style="margin-top: 10%">待更新</p>
      </div>
    </div>

    <Footer :data="$props" />
  </main>
</template>

<script>
import Header from "../components/common/header/Header";
import Footer from "../components/common/footer/Footer";
import linetab4 from "../assets/images/new/Group1773.png";
import linetab5 from "../assets/images/new/Group1774.png";
import linetab6 from "../assets/images/new/Group1772.png";

export default {
  name: "BlogListPage",
  components: { Footer, Header },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isContent1: true,
      isContent2: false,
      isContent3: false,
      tabImg: linetab4,
    };
  },
  methods: {
    imgShow(num) {
      if (num == "4") {
        this.isContent1 = true;
        this.isContent2 = false;
        this.isContent3 = false;
        this.tabImg = linetab4;
      } else if (num == "5") {
        this.isContent2 = true;
        this.isContent1 = false;
        this.isContent3 = false;
        this.tabImg = linetab5;
      } else if (num == "6") {
        this.isContent3 = true;
        this.isContent1 = false;
        this.isContent2 = false;
        this.tabImg = linetab6;
      }
    },
  },
};
</script>
<style scoped>
.wordPart {
  position: relative;
  background-color: #fff;
}
.Group0000 {
  width: 100%;
}
.middlePart {
  width: 60%;
  height: 100%;
  /* background-color: aquamarine; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
}
.Group1768 {
  width: 40%;
  margin-bottom: 20px;
}
.Group1765 {
  display: inline-block;
  margin-right: 20px;
}
.rightppart {
  display: inline-block;
  padding-top: 2%;
}
.rightppart p {
  font-size: 0.8vw;
  color: #6d6d6d;
}
.Group3312 {
  width: 70%;
}

.part11 {
  width: 100%;
  background-color: #fff;
  padding-bottom: 40px;
}
.part11 img {
  margin: 0 auto;
  display: block;
}

.tabbox2 {
  /* height: 470px; */
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
}

.tabbox2 .tabImg {
  width: 55%;
  display: block !important;
  margin: 0 auto !important;
}

.tabbox2 .btn1 {
  width: 9%;
  height: 10vh;
  /* background-color: red; */
  position: absolute;
  top: 22%;
  left: 22%;
}
.tabbox2 .btn2 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(0, 255, 55); */
  position: absolute;
  top: 43%;
  left: 22%;
}
.tabbox2 .btn3 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(255, 0, 149); */
  position: absolute;
  top: 66%;
  left: 22%;
}

.tabbox2 .wordbox {
  width: 23%;
  height: 26vh;
  /* background-color: red; */
  position: absolute;
  top: 19%;
  left: 38%;
}

.tabbox2 .wordbox p {
  color: #555555;
  font-size: 0.8vw;
  margin-bottom: 0px;
}

.tabbox2 .boxul {
  list-style: none;
  margin-top: 16%;
  margin-left: -5%;
}

.tabbox2 .boxli {
  width: 28%;
  height: 6vh;
  background: linear-gradient(to bottom right, #35e9c8, #008985);
  display: inline-block;
  margin-right: 10px;
  line-height: 6vh;
  font-size: 0.8vw;
  color: #fff;
  text-align: center;
  border-radius: 1vh;
}
</style>
