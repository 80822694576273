<template>
  <div v-show="pans.length" class="tabs">
    <div class="tab-title">
      <div
        v-for="(item, index) in pans"
        :key="item.id"
        class="item"
        :class="{ active: currentActive === index }"
        @click="changeTab(index)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "horizontal/vertical",
    },
    defaultActive: {
      type: Number,
      default: 0,
    },
    defaultColor: {
      type: String,
      default: "#409EFF",
    },
    pans: {
      type: Array,
      default: [],
    },
  },
  data: () => {
    return {
      currentActive: 0,
    };
  },
  computed: {},
  watch: {
    defaultActive: {
      handler(newVal) {
        this.currentActive = newVal;
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {
    changeTab(val) {
      this.currentActive = val;
      this.$emit("changeTab", this.currentActive);
    },
  },
};
</script>
<style scoped lang="scss">
::root {
  --color: "#409EFF";
}
.tabs {
  .tab-title {
    display: flex;
    // align-items: flex-start;
    align-items: stretch; // 侧边栏时，使侧栏高度与内容高度一致，按最高的显示
    margin-bottom: 14px;

    .item {
      padding: 20px;
      /* padding-bottom: 20px; */
      cursor: pointer;
      white-space: nowrap;
    }
    .active {
      // color: var(--color);
      color: #409eff;
      /* padding-bottom: 15px; // 修正边框值：20px - 5px = 15px */
      border-bottom: 2px solid #409eff;
    }
  }
}
</style>
