<template>
  <main class="page-wrapper">
    <Header :data="$props" />

    <div class="wordPart">
      <img
        src="../assets/images/new/pageThreeBg.png"
        style="width: 100%"
        alt=""
      />
      <div class="word">
        <p>
          校务通(K12)由我司潜心专研开发的全国首个精准教学+慧学服务自助云打印智能终端。
        </p>
        <p>
          主要解决学校因为教学和校务需要而产生的资料打印复印。通过微印精准教学四大模块六大系统整体提升合作学校教学的针对性和有效性，缩小与一类学校均分差。
        </p>
        <p>
          K12校务智能自助终端以校务的全过程管理为轴:线，对校办、教务、班级等产生的作业、通知、证明、成绩单、申请表等各类通用、制式化的文件资料实现一处查询、预览，一键打印。
        </p>
        <img src="../assets/images/new/Group312.png" alt="" />
      </div>
    </div>

    <div class="tabbox1">
      <img src="../assets/images/new/Group302.png" alt="" />
      <div class="current">
        <img
          src="../assets/images/new/Maskgroup.png"
          class="maskgroup"
          alt=""
        />
        <div class="box">
          <div class="tab1" v-for="(item, index) in partList" :key="index">
            <div
              class="part1"
              @mouseenter="showChange1(item.show, index)"
              v-show="!item.show"
            >
              <p style="font-size: 2.6vw">{{ item.id }}.</p>
              <p style="font-size: 1.1vw; color: rgb(48, 48, 48)">
                {{ item.name }}
              </p>
            </div>
            <div
              class="part2"
              @mouseleave="showChange2(item.show, index)"
              v-show="item.show"
            >
              <p style="font-size: 2.6vw">{{ item.id }}.</p>
              <p style="font-size: 1vw; margin-top: -12%">{{ item.name }}</p>
              <p style="font-size: 0.7vw; color: rgb(47, 46, 46)">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-part">
      <img
        src="../assets/images/new/Group302.png"
        alt="Image"
        style="
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          display: block;
          padding-top: 30px;
        "
      />

      <div class="tabBox">
        <tabs
          :pans="panList"
          :default-active="0"
          class="build-tabs"
          @changeTab="changeTab"
        >
        </tabs>
        <tab-pane :index="0" :current-index="currentIndex">
          <div class="tabContant">
            <img
              src="../assets/images/new/Maskgroup1.png"
              alt="Image"
              style="
                width: 40%;
                display: inline-block;
                margin-left: 10%;
                margin-top: 90px;
              "
            />

            <div class="wordmore">
              <div style="display: flex; flex-direction: row">
                <img
                  src="../assets/images/new/Group753.png"
                  alt="Image"
                  style="
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: inline-block;
                    margin-left: 5%;
                    margin-right: 5%;
                  "
                />
                <div style="display: flex; flex-direction: column">
                  <div style="flex: 1">
                    31寸触摸大屏、自带题库、A3/A4双面60页/分钟，无缝转换打印、扫描
                  </div>
                  <div style="margin-bottom: 3%">
                    打印、扫描结果，设备自动保存并上传数据库
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane :index="1" :current-index="currentIndex">
          <div class="tabContant">
            <img
              src="../assets/images/new/Maskgroup2.png"
              alt="Image"
              style="
                width: 40%;
                display: inline-block;
                margin-left: 10%;
                margin-top: 90px;
              "
            />
            <div class="wordmore">
              <div style="display: flex; flex-direction: row">
                <img
                  src="../assets/images/new/Group753.png"
                  alt="Image"
                  style="
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: inline-block;
                    margin-left: 5%;
                    margin-right: 5%;
                  "
                />
                <div style="display: flex; flex-direction: column">
                  <div style="flex: 1">
                    高清移动摄像头内置自研芯片，可更换普通笔芯。
                  </div>
                  <div style="margin-bottom: 3%">
                    高清移动摄像头内置自研芯片，可更换普通笔芯。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
      </div>
    </div>

    <div class="tabbox2">
      <img :src="tabImg" class="tabImg" alt="" />

      <div class="btn1" @click="imgShow('4')"></div>
      <div class="btn2" @click="imgShow('5')"></div>
      <div class="btn3" @click="imgShow('6')"></div>
      <div class="wordbox" v-show="isContent1">
        <p>i5-4460四核3.2HZ /128G/H81主板10COM11</p>
        <p style="margin-top: 15px">正版windows</p>
        <p>打印速度30+页/分钟，2000页纸盒</p>
        <p>金诚信读卡器GP1100AF(1000万像素自动对焦)高拍仪</p>
        <p>1.55MM钢版、喷塑、21.5触摸屏50CM*50CM*50CM，75KG</p>

        <ul class="boxul">
          <li class="boxli">高质量</li>
          <li class="boxli">高速度</li>
          <li class="boxli">高配置</li>
        </ul>
      </div>

      <div class="wordbox" v-show="isContent2">
        <p>目前各级政府服务中心均菜购了条口部行专用首助设备，如:</p>
        <p>·人社部门</p>
        <p>社保证明查询、打印一体机;</p>
        <p>·市监部门</p>
        <p>工商信息查询、执照打印一体机;</p>
        <p>·行政审批(大数据)部门政务查询一体机等。</p>
        <p>基本都是专用设备，偏政务审批服务，下沉到街道、社区;</p>

        <ul class="boxul" style="margin-top: 11%">
          <li class="boxli">高质量</li>
          <li class="boxli">高速度</li>
          <li class="boxli">高配置</li>
        </ul>
      </div>

      <div class="wordbox" v-show="isContent3">
        <p>
          1）打印复印方面我们主要竞品为学校打印印室外包，外包优势成本低，缺点是教师与文印室文件传输和交付过程效率不是很高，打印、复印教学资料未有效形成学校数字资产;一般不对学生开放，满足不了学生打印、复印需求。
        </p>
        <p style="margin-top: 15px">
          2)
          精准教学方面我们主要竞品为智学网、学科网等精准教学平台，他们在题库组卷、错题分析、学情报告方面优势明显，目前平台向学校提供的是有偿服务，服务费XXX元/年。自动阅卷需要硬件支持，目前平台无硬件支持自助自动阅卷，需要约定时间由平台方安排人员上门服务，导致时效性不够。另外平台之间数据无法融合，导致各自出具学情报告遇到学校自购的纸质作业试卷更是无法数字化
        </p>
      </div>
    </div>

    <div class="main-content">
      <slot></slot>
    </div>

    <FooterTwo :data="$props" />
  </main>
</template>

<script>
import Header from "../components/common/header/Header";
import FooterTwo from "../components/common/footer/FooterTwo";

import linetab4 from "../assets/images/new/tabgroup1.png";
import linetab5 from "../assets/images/new/tabgroup2.png";
import linetab6 from "../assets/images/new/tabgroup3.png";

import tabs from "../components/test/tabs.vue";
import tabPane from "../components/test/tab-pane.vue";
export default {
  name: "BlogGridSidebarPage",
  components: { FooterTwo, Header, tabs, tabPane },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isContent1: true,
      isContent2: false,
      isContent3: false,
      carvalue: 0,
      panList: [
        {
          id: 1,
          index: 1,
          label: "自动打印阅卷智能终端",
        },
        {
          id: 2,
          index: 2,
          label: "智能点阵笔",
        },
      ],
      currentIndex: 0,
      tabImg: linetab4,
      partShow1: false,
      partShow2: false,
      partShow3: false,
      partShow4: false,
      partShow5: false,
      partShow6: false,
      partList: [
        {
          id: "01",
          name: "教学辅助系统",
          content:
            "教学辅助系统集教学案、作业、试卷、题库等模板及最新数据与合作学校共享，帮助合作学校提升教学质量。",
          show: false,
        },
        {
          id: "02",
          name: "自动阅卷系统",
          content:
            "自助阅卷系统集三种阅卷模式，分别为原卷、答题卡、点阵笔扫描阅卷；提高教师阅卷效率，节约出时间用于更多研究教学。",
          show: false,
        },
        {
          id: "03",
          name: "学情分析系统",
          content:
            "学情分析系统采取两大维度，课堂内外的学习态度和知识点掌握的采集分析，第一时间向学校管理层、教师、家长推送，全方位了解学生状况，科学制定学习计划。",
          show: false,
        },
        {
          id: "04",
          name: "教学测评系统",
          content:
            "教学测评系统是以教师日常备课、听课、批改、带班成绩等为基础数据，综合测算后的推进率为依据。为学校每年教师评优提供客观数据。",
          show: false,
        },
        {
          id: "05",
          name: "家长互动平台",
          content:
            "家校互动系统是以学校、班级为单位发布信息及家长、家委会反馈信息的平台。",
          show: false,
        },
        {
          id: "06",
          name: "自助文印管理系统",
          content:
            "自助文印管理系统是完全由个人从上传到打印全过程完成；系统精确记录包含打印者、打印时间、内容、数量等。此系统解决了教师日常打印排队耽误教学时间诟病，也解决学校文印先审后用或先用无法审的现状，达到降本增效效果。",
          show: false,
        },
      ],
    };
  },
  methods: {
    imgShow(num) {
      if (num == "4") {
        this.isContent1 = true;
        this.isContent2 = false;
        this.isContent3 = false;
        this.tabImg = linetab4;
      } else if (num == "5") {
        this.isContent2 = true;
        this.isContent1 = false;
        this.isContent3 = false;
        this.tabImg = linetab5;
      } else if (num == "6") {
        this.isContent3 = true;
        this.isContent1 = false;
        this.isContent2 = false;
        this.tabImg = linetab6;
      }
    },
    showChange1(show, index) {
      console.log(index);
      this.partList[index].show = true;
    },
    showChange2(show, index) {
      this.partList[index].show = false;
    },

    changeTab(index) {
      this.currentIndex = index;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style scoped>
.wordPart {
  position: relative;
}
.wordPart .word {
  position: absolute;
  color: #6d6d6d;
  top: 20.2%;
  left: 39.2%;
  width: 45%;
}
.wordPart .word p {
  font-size: 0.8vw;
}
.wordPart .word img {
  width: 60%;
}
.tabbox1 {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding-top: 30px;
}
.tabbox1 img {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}
.tabbox1 .current {
  width: 60%;
  height: 75%;
  background-color: aqua;
  margin: auto;
  position: relative;
}
.tabbox1 .current .maskgroup {
  width: 100%;
}

.tabbox1 .box {
  display: flex;
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
}

.tabbox1 .tab1,
.tabbox1 .tab2,
.tabbox1 .tab3,
.tabbox1 .tab4,
.tabbox1 .tab5,
.tabbox1 .tab6 {
  flex: 1;
  position: relative;
}

.tabbox1 .box .part1 {
  width: 100%;
  height: 40%;
  background-color: rgba(166, 163, 163, 0.3);

  position: absolute;
  bottom: 0;
  padding-left: 6%;
  padding-top: 14%;
}
.tabbox1 .box .part2 {
  width: 100%;
  height: 100%;
  background-color: rgb(226 123 58/0.3);
  position: absolute;
  top: 0;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30%;
  color: rgb(226, 123, 58);
}
.tabbox1 .box .part2 p {
  color: rgb(226, 123, 58);
}

.tab-part {
  width: 100%;
  /* height: 640px; */
  background: #f9fbff;
  position: relative;
}

.tabBox {
  width: 100%;
  margin-top: 20px;
}

.tabBox >>> .tabs .tab-title {
  position: absolute;
  left: 20%;
}

.tabBox >>> .tabs .tab-title .active {
  color: #f08742;
  border-bottom: 2px solid #f08742;
}

.tabBox >>> .tabs .tab-title .item {
  padding: 10px;
  margin-right: 15px;
}

.tabContant {
  width: 100%;
  /* height: 440px; */
  /* padding-top: 30px; */
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  /* justify-content: center; */
  align-items: center;
}

.wordmore {
  display: inline-block;
  width: 30%;
  height: 60%;
  margin-top: 5%;
}

.tabbox2 {
  /* height: 470px; */
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
}

.tabbox2 .tabImg {
  width: 55%;
  display: block !important;
  margin: 0 auto !important;
}

.tabbox2 .btn1 {
  width: 9%;
  height: 10vh;
  /* background-color: red; */
  position: absolute;
  top: 22%;
  left: 22%;
}
.tabbox2 .btn2 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(0, 255, 55); */
  position: absolute;
  top: 43%;
  left: 22%;
}
.tabbox2 .btn3 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(255, 0, 149); */
  position: absolute;
  top: 66%;
  left: 22%;
}

.tabbox2 .wordbox {
  width: 23%;
  height: 26vh;
  /* background-color: red; */
  position: absolute;
  top: 19%;
  left: 38%;
}

.tabbox2 .wordbox p {
  color: #555555;
  font-size: 0.8vw;
  margin-bottom: 0px;
}

.tabbox2 .boxul {
  list-style: none;
  margin-top: 16%;
  margin-left: -5%;
}

.tabbox2 .boxli {
  width: 28%;
  height: 6vh;
  background: linear-gradient(to bottom right, #fec166, #fd4820);
  display: inline-block;
  margin-right: 10px;
  line-height: 6vh;
  font-size: 0.8vw;
  color: #fff;
  text-align: center;
  border-radius: 1vh;
}
</style>
