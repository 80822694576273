<template>
  <main class="page-wrapper">
    <Header :data="$props" />

    <img src="../assets/images/new/Group1711.png" style="width: 100%" alt="" />
    <div class="wordPart">
      <img
        src="../assets/images/new/Group0000.png"
        class="Group0000"
        style="width: 100%"
        alt=""
      />
      <div class="middlePart">
        <img
          src="../assets/images/new/Group1765.png"
          class="Group1765"
          alt=""
        />
        <div class="rightppart">
          <img
            src="../assets/images/new/Group1768.png"
            alt=""
            class="Group1768"
          />
          <p style="width: 90%">
            校务通(高校)是我司潜心专研开发的全国首个校务服务+惠学服务自助云打印智能终端。
          </p>
          <p style="width: 90%">
            校务通(高校)介绍校务通(高校)以校务服务的全过程管理为轴线，对校办、教务、团委、后勤，学生自身等学习、通知、证明、准考证、成绩单、申请表等各类通用、制式化的文件、附件资料实现一号登录一处查询、预览，一键打印。
          </p>
          <img
            src="../assets/images/new/Group3312.png"
            alt=""
            class="Group3312"
          />
        </div>
      </div>
    </div>

    <div class="part11">
      <img src="../assets/images/new/Group730.png" style="width: 64%" alt="" />
    </div>

    <img src="../assets/images/new/Group731.png" style="width: 100%" alt="" />

    <div class="tabbox2">
      <img :src="tabImg" class="tabImg" alt="" />

      <div class="btn1" @click="imgShow('4')"></div>
      <div class="btn2" @click="imgShow('5')"></div>
      <div class="btn3" @click="imgShow('6')"></div>
      <div class="wordbox" v-show="isContent1">
        <p>系统</p>
        <p>自主研发微印打印控制系统参数</p>
        <p style="margin-top: 10px">配置</p>
        <p>i5-4460四核3.2HZ /128G/H81主板</p>
        <p>10*COM</p>
        <p style="margin-top: 10px">正版windows</p>
        <p>打印速度30+页/分钟，2000页纸盒</p>
        <p>1.55MM钢版、喷塑、21.5触摸屏50CM*50CM*50CM，75KG</p>
      </div>

      <div class="wordbox" v-show="isContent2">
        <p style="margin-top: 20%">
          对校办、教务、团委、后勤，学生自身等学习、通知、证明、准考证、成绩单、申请表等各类通用、制式化的文件、附件资料实现一号登录一处查询、预览，一键打印。
        </p>
      </div>

      <div class="wordbox" v-show="isContent3">
        <p>待更新</p>
      </div>
    </div>

    <FooterThree :data="$props" />
  </main>
</template>

<script>
import Header from "../components/common/header/Header";
import FooterThree from "../components/common/footer/FooterThree";
import linetab4 from "../assets/images/new/Group1771.png";
import linetab5 from "../assets/images/new/Group1769.png";
import linetab6 from "../assets/images/new/Group1770.png";

export default {
  name: "BlogListPage",
  components: { FooterThree, Header },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isContent1: true,
      isContent2: false,
      isContent3: false,
      tabImg: linetab4,
    };
  },
  methods: {
    imgShow(num) {
      if (num == "4") {
        this.isContent1 = true;
        this.isContent2 = false;
        this.isContent3 = false;
        this.tabImg = linetab4;
      } else if (num == "5") {
        this.isContent2 = true;
        this.isContent1 = false;
        this.isContent3 = false;
        this.tabImg = linetab5;
      } else if (num == "6") {
        this.isContent3 = true;
        this.isContent1 = false;
        this.isContent2 = false;
        this.tabImg = linetab6;
      }
    },
  },
};
</script>
<style scoped>
.wordPart {
  position: relative;
  background-color: #fff;
}
.Group0000 {
  width: 100%;
}
.middlePart {
  width: 60%;
  height: 100%;
  /* background-color: aquamarine; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
}
.Group1768 {
  width: 40%;
  margin-bottom: 20px;
}
.Group1765 {
  display: inline-block;
  margin-right: 20px;
}
.rightppart {
  display: inline-block;
  padding-top: 2%;
}
.rightppart p {
  font-size: 0.8vw;
  color: #6d6d6d;
}
.Group3312 {
  width: 70%;
}

.part11 {
  width: 100%;
  background-color: #fff;
  padding-bottom: 40px;
}
.part11 img {
  margin: 0 auto;
  display: block;
}

.tabbox2 {
  /* height: 470px; */
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
}

.tabbox2 .tabImg {
  width: 55%;
  display: block !important;
  margin: 0 auto !important;
}

.tabbox2 .btn1 {
  width: 9%;
  height: 10vh;
  /* background-color: red; */
  position: absolute;
  top: 22%;
  left: 22%;
}
.tabbox2 .btn2 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(0, 255, 55); */
  position: absolute;
  top: 43%;
  left: 22%;
}
.tabbox2 .btn3 {
  width: 9%;
  height: 10vh;
  /* background-color: rgb(255, 0, 149); */
  position: absolute;
  top: 66%;
  left: 22%;
}

.tabbox2 .wordbox {
  width: 23%;
  height: 26vh;
  /* background-color: red; */
  position: absolute;
  top: 19%;
  left: 38%;
}

.tabbox2 .wordbox p {
  color: #fff;
  font-size: 0.8vw;
  margin-bottom: 0px;
}

.tabbox2 .boxul {
  list-style: none;
  margin-top: 16%;
  margin-left: -5%;
}

.tabbox2 .boxli {
  width: 28%;
  height: 6vh;
  background: linear-gradient(to bottom right, #fec166, #fd4820);
  display: inline-block;
  margin-right: 10px;
  line-height: 6vh;
  font-size: 0.8vw;
  color: #fff;
  text-align: center;
  border-radius: 1vh;
}
</style>
