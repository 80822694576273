<template>
  <Layout :footer-style="2">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div style="position: relative">
        <img src="../assets/images/new/bgindex1.png" alt="Image" />
        <div
          class="btn1"
          style="position: absolute; top: 12%; left: 19%; width: 15%"
        >
          <img
            @click="goJump('/blog-grid')"
            src="../assets/images/new/gotry.png"
            alt="Image"
            style="width: auto; height: auto; max-width: 100%; max-height: 100%"
          />
        </div>
        <div class="btn2" style="position: absolute; top: 25.3%; left: 19%">
          <div style="width: 566px; width: 38%; font-size: 0.9vw">
            江苏龙达信息科技有限公司是一家专注于智惠便民和政务服务的科技公司。基于基层便民服务的多年研究及服务经验，利用团队在科技创新领城的经验和技术优势，创新式的提出了“让便民更智慧”
            的发展理念。紧紧围绕“15分钟生活图”的便民诉求，以社区为产品和服务的立足点，打造了以“智慧政务云打印”服务为代表的一系列便民服务科技产品。以“SAAS、PAAS自助云打印平台”
            的为核心技术，结合政务服务的核心诉求，实现政务服务下沉，让社会大众享受到近在咫尺的便民惠民等多项服务。目前，公司已经与国家级江北新区、上海陆家嘴街道等政府便民服务机构及网点达成深度合作，紧密围绕便民政务服务需求，扩充服务能力。
          </div>
          <img
            @click="goJump('/about')"
            src="../assets/images/new/goknowmore.png"
            alt="Image"
            style="
              width: 11%;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              margin-top: 2%;
            "
          />
        </div>
        <div
          class="btn3"
          style="position: absolute; top: 56%; left: 61%; width: 12%"
        >
          <img
            @click="goJump('/blog-grid')"
            src="../assets/images/new/trytry.png"
            alt="Image"
            style="width: auto; height: auto; max-width: 100%; max-height: 100%"
          />
        </div>
        <div
          class="btn4"
          style="position: absolute; top: 72%; left: 19%; width: 12%"
        >
          <img
            @click="goJump('/blog-grid-sidebar')"
            src="../assets/images/new/trytry.png"
            alt="Image"
            style="width: auto; height: auto; max-width: 100%; max-height: 100%"
          />
        </div>
      </div>
      <div class="tab-part">
        <img
          src="../assets/images/new/tabtitle.png"
          alt="Image"
          style="
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            display: block;
            padding-top: 30px;
          "
        />

        <div class="tabBox">
          <tabs
            :pans="panList"
            :default-active="0"
            class="build-tabs"
            @changeTab="changeTab"
          >
          </tabs>
          <tab-pane :index="0" :current-index="currentIndex">
            <div class="tabContant">
              <img
                src="../assets/images/new/tab1.png"
                alt="Image"
                style="
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  margin: 0 auto;
                  display: block;
                  margin-top: 28px;
                "
              />
              <div class="knowMore">
                <img
                  @click="goJump('/blog-grid')"
                  src="../assets/images/new/knowmore.png"
                  alt="Image"
                  style="
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin-top: 28px;
                  "
                />
              </div>
            </div>
          </tab-pane>
          <tab-pane :index="1" :current-index="currentIndex">
            <div class="tabContant">
              <img
                src="../assets/images/new/tab2.png"
                alt="Image"
                style="
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  margin: 0 auto;
                  display: block;
                  margin-top: 28px;
                "
              />
              <div class="knowMore">
                <img
                  @click="goJump('/blog-grid-sidebar')"
                  src="../assets/images/new/knowmore.png"
                  alt="Image"
                  style="
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin-top: 28px;
                  "
                />
              </div>
            </div>
          </tab-pane>
          <tab-pane :index="2" :current-index="currentIndex">
            <div class="tabContant">
              <img
                src="../assets/images/new/tab3.png"
                alt="Image"
                style="
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  margin: 0 auto;
                  display: block;
                  margin-top: 28px;
                "
              />
              <div class="knowMore">
                <img
                  @click="goJump('/blog-list-sidebar')"
                  src="../assets/images/new/knowmore.png"
                  alt="Image"
                  style="
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin-top: 28px;
                  "
                />
              </div>
            </div>
          </tab-pane>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div style="width: 100%; background-color: #fff; padding-top: 50px">
        <img
          src="../assets/images/new/friends.png"
          alt=""
          style="
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            display: block;
          "
        />
        <div class="carPart">
          <div class="box">
            <Carousel v-model="carvalue" :loop="true" :radius-dot="true">
              <CarouselItem>
                <div class="demo-carousel">
                  <img
                    src="../assets/images/new/schools1.png"
                    style="width: 100%"
                    alt=""
                  />
                  <img
                    src="../assets/images/new/schools2.png"
                    alt=""
                    style="margin-top: 20px; width: 100%"
                  />
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Tab from "../components/elements/tab/Tab";
import tabs from "../components/test/tabs.vue";
import tabPane from "../components/test/tab-pane.vue";

export default {
  name: "Demo",
  components: { Tab, Layout, tabs, tabPane },
  data() {
    return {
      carvalue: 0,
      panList: [
        {
          id: 1,
          index: 1,
          label: "社区",
        },
        {
          id: 2,
          index: 2,
          label: "校园",
        },
        {
          id: 3,
          index: 3,
          label: "医务",
        },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.currentIndex = index;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    goJump(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.tab-part {
  width: 100%;
  height: 640px;
  background: #f9fbff;
  position: relative;
}

.tabBox {
  width: 100%;
  margin-top: 20px;
}

.tabBox >>> .tabs .tab-title {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.tabBox >>> .tabs .tab-title .item {
  padding: 10px;
  margin-right: 15px;
}

.tabContant {
  width: 100%;
  height: 440px;
  padding-top: 60px;
}

.knowMore {
  position: absolute;
  left: 50%;
  transform: translate(-100%, -100%);
}

.carPart {
  width: 100%;
  height: 300px;
}
.carPart .box {
  width: 50%;
  /* background-color: aqua; */
  margin: 0 auto;
  /* height: 400px; */
}

.demo-carousel {
  height: 200px;
  margin-top: 30px;
}
</style>
