<template>
  <Layout>
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row mt--40 row--15">
          <div class="col-lg-7">
            <ContactForm />
          </div>
          <div class="col-lg-5 mt_md--30 mt_sm--30">
            <GoogleMap />
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: "ContactPage",
  components: {
    ContactForm,
    GoogleMap,
    Icon,
    SectionTitle,
    Breadcrumb,
    Layout,
  },
};
</script>
