<template>
  <main class="page-wrapper">
    <Header :data="$props" />

    <div class="wordPart">
      <img src="../assets/images/new/pageTwoBg.png" alt="" />
      <div class="word">
        微事通(便民)江苏龙达积极响应国家“十四五”规划提出:“加快城乡社区服务体系建设，充分发挥全国一体化政务服务平台作用，推动“互联网+政务服务”向乡镇(街道）、（社区）延伸覆盖。社区服务设施开放和服务时间一般不少于每天8小时，群众关切项目应开展24小时线上服务，保留必要线下办事服务渠道，及时响应居民需求，加快部署政务通自助一体化服务，完善村(社区)政务自助便民服务网络布局。<span>产品以道为中心</span>，在便民服务方面持续探索，<span>以“公共服务不出网格”为目标，</span>真正做到事项要高频、功能要集成、权限要打通、场景要落地、群众要有感。
      </div>
    </div>

    <div class="tabbox1">
      <img
        src="../assets/images/new/pageTwoTabBg1.png"
        style="width: 100%"
        alt=""
      />
      <div class="box">
        <img :src="numImg" class="tabImg" alt="" />

        <div class="btn1" @click="imgShow('1')"></div>
        <div class="btn2" @click="imgShow('2')"></div>
        <div class="btn3" @click="imgShow('3')"></div>
      </div>
    </div>
    <div class="tabbox2">
      <img :src="tabImg" class="tabImg" alt="" />

      <div class="btn1" @click="imgShow('4')"></div>
      <div class="btn2" @click="imgShow('5')"></div>
      <div class="btn3" @click="imgShow('6')"></div>

      <div class="wordbox" v-show="isContent1">
        <p>系统</p>
        <p>自主研发微印打印控制系统/总台接口系统</p>
        <p>参数配置</p>
        <p>5-4460四核3.2HZ /128G/H81主板 10*COM</p>
        <p>正版Windows</p>
        <p>交通卡读卡器/银行卡读卡器/数字键盘/二维码读卡器</p>
        <p>拾音器/扩音器</p>
        <p>
          GP1100AF(1000万像素自动对焦)高拍仪打印速度30+页/分钟，250页+50页纸盒
        </p>
        <p>
          1.55MM钢版、喷塑、32寸触摸屏身份证读卡器/社保卡读卡器/纸币充值机(选配)
        </p>

        <ul class="boxul">
          <li class="boxli">i5-4460四核</li>
          <li class="boxli">128G</li>
          <li class="boxli">H81主板</li>
        </ul>
      </div>
      <div class="wordbox" v-show="isContent2" style="top: 15%">
        <p>目前各级政府服务中心均采购了自助设备，如:</p>
        <p>·人社部门</p>
        <p>社保证明查询、打印一体机;</p>
        <p>·市监部门</p>
        <p>工商信息查询、执照打印一体机;</p>
        <p>·行政审批(大数据)部门政务查询一体机等。</p>
        <p>基本都是专用设备，偏政务审批服务，下沉到街道、社区;</p>

        <ul class="boxul" style="margin-top: 11%">
          <li class="boxli">有点兴趣</li>
          <li class="boxli">占地面积大</li>
          <li class="boxli">采购成本大</li>
        </ul>
      </div>

      <div class="wordbox" v-show="isContent3">
        <p style="margin-top: 20%">
          政务服务一体机，集成了大部分政府部门查询功能，但大部分审批权限和数据接口未联接，设备销售价格在5-20万之间。
        </p>
      </div>
    </div>
    <div class="main-content">
      <slot></slot>
    </div>

    <Footer :data="$props" />
  </main>
</template>

<script>
import Header from "../components/common/header/Header";
import Footer from "../components/common/footer/Footer";
import linetab1 from "../assets/images/new/linetab1.png";
import linetab2 from "../assets/images/new/linetab2.png";
import linetab3 from "../assets/images/new/linetab3.png";
import linetab4 from "../assets/images/new/linetab0.png";
import linetab5 from "../assets/images/new/linetab00.png";
import linetab6 from "../assets/images/new/linetab000.png";
export default {
  name: "BlogGridPage",
  components: { Footer, Header },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 1,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      numImg: linetab1,
      tabImg: linetab6,
      isContent1: true,
      isContent2: false,
      isContent3: false,
      carvalue: 0,
    };
  },
  methods: {
    imgShow(num) {
      if (num == "1") {
        this.numImg = linetab1;
      } else if (num == "2") {
        this.numImg = linetab2;
      } else if (num == "3") {
        this.numImg = linetab3;
      } else if (num == "4") {
        this.tabImg = linetab4;
        this.isContent1 = true;
        this.isContent2 = false;
        this.isContent3 = false;
      } else if (num == "5") {
        this.tabImg = linetab5;
        this.isContent2 = true;
        this.isContent1 = false;
        this.isContent3 = false;
      } else if (num == "6") {
        this.tabImg = linetab6;
        this.isContent3 = true;
        this.isContent1 = false;
        this.isContent2 = false;
      }
    },
  },
};
</script>
<style scoped>
.wordPart {
  position: relative;
}
.wordPart .word {
  position: absolute;
  color: #333333;
  top: 54.5%;
  left: 19%;
  width: 29%;
  font-size: 0.8vw;
}
.wordPart .word span {
  color: #0728b9;
}

.tabbox1 {
  position: relative;
  top: 20%;
}
.tabbox1 .box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.tabbox1 .box .tabImg {
  width: 64%;
  display: block !important;
  margin: 0 auto !important;
  margin-top: 7% !important;
}

.tabbox1 .btn1 {
  width: 12%;
  height: 12vh;
  /* background-color: red; */
  position: absolute;
  top: 31%;
  left: 45%;
}
.tabbox1 .btn2 {
  width: 12%;
  height: 12vh;
  /* background-color: rgb(0, 255, 55); */
  position: absolute;
  top: 51%;
  left: 45%;
}
.tabbox1 .btn3 {
  width: 12%;
  height: 12vh;
  /* background-color: rgb(255, 0, 149); */
  position: absolute;
  top: 71%;
  left: 45%;
}
.tabbox2 {
  /* height: 470px; */
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
}

.tabbox2 .tabImg {
  width: 55%;
  display: block !important;
  margin: 0 auto !important;
}

.tabbox2 .btn1 {
  width: 9%;
  height: 12vh;
  /* background-color: red; */
  position: absolute;
  top: 20%;
  left: 22%;
}
.tabbox2 .btn2 {
  width: 9%;
  height: 12vh;
  /* background-color: rgb(0, 255, 55); */
  position: absolute;
  top: 43%;
  left: 22%;
}
.tabbox2 .btn3 {
  width: 9%;
  height: 12vh;
  /* background-color: rgb(255, 0, 149); */
  position: absolute;
  top: 66%;
  left: 22%;
}

.tabbox2 .wordbox {
  width: 23%;
  height: 26vh;
  /* background-color: red; */
  position: absolute;
  top: 10%;
  left: 38%;
}

.tabbox2 .wordbox p {
  color: #fff;
  font-size: 0.8vw;
  margin-bottom: 0px;
}

.tabbox2 .boxul {
  list-style: none;
  margin-top: 1%;
  margin-left: -5%;
}

.tabbox2 .boxli {
  width: 28%;
  height: 5.5vh;
  background: linear-gradient(to bottom right, #61a5ff, #9361ff);
  display: inline-block;
  margin-right: 10px;
  line-height: 5.5vh;
  font-size: 0.8vw;
  color: #fff;
  text-align: center;
  border-radius: 1vh;
}
</style>
